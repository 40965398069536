import { useState, useEffect, useRef, useReducer } from "react";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ChecklistTemplateDetail from "./ChecklistTemplateDetail";
import ChecklistSectionCard from "./ChecklistSectionCard";
import { Button, Dialog , Tooltip, Box } from "@mui/material";
import { cloneDeep } from 'lodash';
import axios from "axios";
import { fetchUrl } from "Urls";
import { makeTestRecId} from "api";
import { useSelector } from "react-redux";
import ToastComponent from "components/ToastComponent";
import Spinner from "components/spinner";
import { IdleTimer } from "components/IdleTimer";
//import { isEqual } from 'lodash';
import { ConfirmDialog ,ConfirmDialogCheckbox } from "components/DrawerComponent/DisciplineSettingDialog";
const sectionQuetion = {
  /** Id, Name , Response_Type__c , Numbering_Sequence__c , Description__c ,Form_Template_Section__c ,Response_Options__c, Question__c */
  Numbering_Sequence__c: 1,
  Question__c: null, //'Question'
  Description__c: null, //'Description'
  Response_Options__c: JSON.stringify([{ label: 'Yes', value: 'Yes', key: 'Yes' , action:'na' },
  { label: 'No', value: 'No', key: 'No' , action:'na'},
  { label: 'NA', value: 'NA', key: 'NA' , action:'na'}]),
  responseOptions: [{ label: 'Yes', value: 'Yes', key: 'Yes', action:'na' },
  { label: 'No', value: 'No', key: 'No' , action:'na'},
  { label: 'NA', value: 'NA', key: 'NA' , action:'na'}],
  Response_Type__c: 'Preconfigured',
  Optional__c: false,
  Conditional_Logic__c: false,
};
const sectionObject = {
  /**Id,Name,Description__c,Form_Template__c,Numbering_Sequence__c,items */
  Numbering_Sequence__c: 1,
  Name: null, // Untitled Section
  Description__c: null, //'Description (optional)'
  items: [sectionQuetion],
};

const sectionObjectInitialState = [{
  ...sectionObject
}];
/*const initialState = {
  sectionCurrentList: sectionObjectInitialState,
  history: [{ sectionCurrentList: sectionObjectInitialState }],
  currentIndex: 0,
};*/
const BuildChecklistTemplate = (props) => {
  const idleTimerRef = useRef(null);
  const checklistServerId = useRef(null) ;
  const { templateId, onCancel, credential, _templateOptions, splitButtonAction, onAccept, templateList } = props;
  const [projectTemplateId, setProjectTemplateId] = useState();
  const [showSpinner, setSpinner] = useState(false);
  const { userData = {} } = useSelector((state) => state.userData);
  const [publishButtonDisabled, setPublishButtonDisabled] = useState(false);
  const [pendingChanges, setPendingChanges] = useState(false);
  const [templateUpdated, setTemplateUpdatedState] = useState(false);
  const templateUpdatedRef = useRef(templateUpdated);
  const [openFormDetails, setFormDetail] = useState(false);
  const [duplicatTemplate, setDuplicateTemplate] = useState(false);
  const [templateOptions, setTemplateOptions] = useState(_templateOptions);
  const [currentStep, setCurrentStep] = useState(1);
  const [sfObj, setsfObj] = useState(defaultObj());
  const [templatePublished , setTemplatePublished] = useState(false);
  const [templateActive , setTemplateActive] = useState(false);
  const [dispatchjDetail, setDispatchDetail] = useState(null);
  const __sfObj = useRef(null);
  const [selectedSection, setSelectedSection] = useState();
  const [currentEditableElement, setEditableElement] = useState();
  const [clickAwayPressed, setClickAway] = useState(false);
  const [skipQuestionDialog, setSkipQuestionDialog] = useState(false);
  const [openQuestionDeleteDialog, setOpenQuestionDeleteDialog] = useState(false);
  const [openSectionDeleteDialog, setOpenSectionDeleteDialog] = useState(false);
  const [skipSectionDialog, setSkipSectionDialog] = useState(false);
  const [toastMessage, setToastMessage] = useState();
  const [openPendingWarning, setPendingWaring] = useState(false);
  const confirmDeleteSectionDialog = {
    title: 'Delete Section?',
    content: 'Are you sure you want to delete this section? When deleted, all questions under this section will be deleted as well. This action can be undone using the undo button.',
    action1: "Cancel",
    action2: "Yes, Delete "
  };
  const confirmDeleteQuestionDialog = {
    title: 'Delete Question?',
    content: 'Are you sure you want to delete this question? This action can be undone using the undo button.',
    action1: "Cancel",
    action2: "Yes, Delete"
  };
  const pendinChangesModal = {
    title: 'Unsaved Changes',
    content: 'Do you want to save changes before leaving this page? If not, changes you have made will not be saved.',
    action1: "Cancel",
    action2: "Don't Save",
    action3: "Save Changes"
  };
  const setTemplateUpdated =(value)=>{
    templateUpdatedRef.current = value;
    setTemplateUpdatedState(value);
  }
  const [sectionCurrentList, dispatch] = useReducer(reducerF, []); // to keep this at very last
 /* const [{ sectionCurrentList, history, currentIndex }, dispatch] = useReducer(reducerF, initialState);
  useEffect(() => {
    const newHistory = history.slice(0, currentIndex + 1);
    const lastHistoryItem = newHistory[newHistory.length - 1];
  
    console.log('sectionCurrentList:', sectionCurrentList);
    console.log('lastHistoryItem:', lastHistoryItem);
    console.log('newHistory before update:', newHistory);
  
    if (!isEqual(lastHistoryItem?.sectionCurrentList, sectionCurrentList)) {
      // Only push a new record if there's a change
      newHistory.push({ sectionCurrentList: [...sectionCurrentList] });
      console.log("newHistory after update:", newHistory);
      dispatch({ type: 'SET_HISTORY', payload: newHistory });
    }
  }, [sectionCurrentList]);
  

  const handleUndo = () => {
    console.log("initialState");
    dispatch({ type: 'UNDO' });
  };

  const handleRedo = () => {
    console.log("initialStaqte");
    dispatch({ type: 'REDO' });
  };*/
  const builderBodyCenterRef = useRef(null);
  const builderBodyRightToolRef = useRef(null);
  useEffect(() => {
    //console.log('currentEditableElement ::',currentEditableElement);
    const handleScroll = () => {
      if(builderBodyCenterRef.current){
        if(currentEditableElement || selectedSection){
          let getelemen ;
          if(currentEditableElement && selectedSection){
            if(currentEditableElement == selectedSection){
              getelemen = '.section_selected';
            }else{
              getelemen = '.Question_card';
            }
          }else if(selectedSection && !currentEditableElement){
            getelemen = '.section_selected';
          }else if(!selectedSection && currentEditableElement){
            getelemen = '.Question_card';
          }else if(currentEditableElement != selectedSection){
            getelemen = '.Question_card';
          }
          //let getelemen = currentEditableElement == selectedSection ? '.section_selected' : selectedSection ? 'section_selected' : '.Question_card'  ;
          //console.log('getelemen ::',getelemen);
          if(getelemen){
            const sectionElement = builderBodyCenterRef.current.querySelector(getelemen);
            if (sectionElement && builderBodyRightToolRef.current) {
              const sectionRect = sectionElement.getBoundingClientRect();
              const centerRect = builderBodyCenterRef.current.getBoundingClientRect();
              const toolbarRect = builderBodyRightToolRef.current.getBoundingClientRect();
              let offset = sectionRect.top - centerRect.top;
              const minOffset = 0;
              const maxOffset = centerRect.height - toolbarRect.height;
              offset = Math.max(minOffset, Math.min(offset, maxOffset));
              builderBodyRightToolRef.current.style.transform = `translateY(${offset}px)`;
              return;
            }
          } 
        }
      }
      if(builderBodyRightToolRef.current){
        builderBodyRightToolRef.current.style.transform = null;
      }
    };
    handleScroll();
    const centerElement = builderBodyCenterRef.current;
    if(centerElement){
      centerElement.addEventListener('scroll', handleScroll);
    }

    // Clean up event listener on unmount
    return () => {
      if(centerElement){
        centerElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [selectedSection , currentEditableElement]);
  useEffect(() => {
    //console.log('files use effect ::',userData?.projectId,_projectId);
    if (templateId) {
      //fetch template data
      getTemplateDetailsFromServer(templateId);
    } else {
      setFormDetail(true);
    }
  }, []);
  useEffect(() => {
    setTemplateOptions(_templateOptions);
  }, [_templateOptions]);
  useEffect(() => {
    setEditableElement(null);
  }, [selectedSection]);
  useEffect(() => {
    if (userData?.checklistSkipDeleteQuestionDialog) {
      setSkipQuestionDialog(true);
    } else {
      setSkipQuestionDialog(false);
    }
    if (userData?.checklistSkipDeleteSectionDialog) {
      setSkipSectionDialog(true);
    } else {
      setSkipSectionDialog(false);
    }
  }, [userData?.checklistSkipDeleteQuestionDialog , userData?.checklistSkipDeleteSectionDialog]);
  //console.log('Build templateOptions :: ',_templateOptions ,templateOptions);
  function defaultObj() {
    return { Name: '', Form_Type__c: 'Checklist' };
  }
  const [requiredMissing, setRequiredMissing] = useState(true);

  function setVariables(_sfObj , _sectionList = sectionCurrentList) {
    __sfObj.current = _sfObj;
    if(_sfObj?.Id){
      checklistServerId.current = _sfObj?.Id;
    }
    setRequiredMissing(!checkReadyToSave(_sfObj));
    setsfObj(_sfObj);
    //console.log('setVariabl es sectionCurrentList :: '+JSON.stringify(_sectionList));
    dispatch({type: 'HANDLE_SECTION_REPLACE' , sectionList : _sectionList});
  }
  const getTemplateDetailsFromServer = async (_templateId = checklistServerId?.current, clone, rec) => {
    setSpinner(true);
    await axios.get(`${fetchUrl}/getFormTemplate/${_templateId}?projectId=${credential?.projectId}`)
    .then((res) => {
      //console.log("count:::: " + JSON.stringify(res));
      if (res.status === 200) {
        //{formObj : cObj , docDetail : docReturn}
        if(res.data?.formObj){
          if(clone){
             setSpinner(true);
            const RemoveSectionIdProperty = res.data.docDetail.map(({ Id, Form_Template__c, items, ...rest }) => ({
              items: items.map(({ Id, ans, ...itemRest }) => itemRest),
              ...rest
            }));
            const reqBody = {checkObj : rec , sections : RemoveSectionIdProperty};
             axios.post(`${fetchUrl}/SaveCheckListTemplate`,reqBody).then((res) => {
              getTemplateDetailsFromServer(rec.Id);
              if(duplicatTemplate){
                let tmsg = `${rec.Name} has been duplicated`;
                  setToastMessage(tmsg);
                  setDuplicateTemplate(false);
              }
             })
          }else{
            checklistServerId.current = templateId;
            setTemplatePublished(res.data.formObj.Status__c === 'Published');
            setTemplateActive(res.data.formObj.Project_Form_Templates__r[0].Status__c === 'Active');
            if(res.data.formObj.Project_Form_Templates__r?.length > 0){
              setProjectTemplateId(res.data.formObj.Project_Form_Templates__r[0].Id);
            }
            console.log("res.data.formObj :::", res.data.formObj);
          setVariables(res.data.formObj , res.data.docDetail );
          }
          return;
        }
      }
    }).catch((err) => {
      console.log(err);
    });
    setSpinner(false);
  };
  const handleCloneTemplate = async (selectedTemplateId, rec) => {
    getTemplateDetailsFromServer(selectedTemplateId , 'clone', rec)
  }
  function checkReadyToSave(_sfObj) {
    //console.log('checkReadyToSave');
    let reqfields = ['Template_Category__c', 'Name'];
    if (_sfObj) {
      for (let f of reqfields) {
        if (!_sfObj[f] || _sfObj[f] == '' || _sfObj[f] == null) {
          //console.log('checkReadyToSave :: '+f);
          return false;
        }
      }
    }
    return true;
  }
  const handleClickAway = (e) => {
    setClickAway((prew) =>!prew);
  };
  const handleAddNewQuestion = () => {
    //console.log('selectedSection :: ',selectedSection);
    if(selectedSection){
      let findInd = sectionCurrentList?.findIndex((res) => res.identifier == selectedSection);
      //console.log('findInd :: ',findInd);
      if(findInd > -1){
        let add_ = true;
        if(sectionCurrentList[findInd].items?.length > 0  ){
          for(const itm of sectionCurrentList[findInd]?.items){
            if(itm.Response_Type__c === 'Signature'){
              add_ = false;
              break;
            }
          }
        }
        if(add_){
          dispatch({type: 'ADD_QUESTION', sectionIndex : findInd});
        }
      }
    }
  };
  const handleAddNewSignature = () => {
    let addSignsection = true;
    let addSign = false;
    let fin_ind;
    if(sectionCurrentList?.length > 0 ){
      for(let i = 0; i < sectionCurrentList.length; i++){
        const sec = sectionCurrentList[i];
        if(sec.Name === 'Signature'){
          addSignsection = false;
          addSign = true;
          fin_ind = i;
          break;
        }
      }
    }
    if(addSignsection){
      dispatch({ type: 'ADD_SIGN_SECTION' });
    }else if(addSign){
      dispatch({type: 'ADD_QUESTION', sectionIndex : fin_ind});
    }
  };
  const handleAddNewSection = () => {
    dispatch({ type: 'ADD_SECTION' });
  };
  const onPublishTemplate = async () => {
    let fff = sfObj;
    fff.Status__c = 'Published';
    setVariables(fff);
    // need to put status as published for the template and then save
    let tmsg = `Template Details has been updated.`;
    let val = await handleClickSave(tmsg);
    if(val==true){
      setTimeout(()=>{handleOnClose()},2000);
    }
  };
  const onSaveAsDraft = async (closeOnSave)=>{
    let tmsg = `Template Details has been updated.`;
    let val = await handleClickSave(tmsg);
    if(val === true ){
      if(closeOnSave === true){
        handleOnClose()
      }else{
        getTemplateDetailsFromServer();
      }
    }
  };
  const handleOnClose = ()=>{
    onCancel(templateUpdatedRef.current);
  };
  const onIdelAutoSave = async ()=>{
    if(!openFormDetails){
      if(pendingChanges){
        onSaveAsDraft();
      }
    }
  };
  async function handleClickSave(tmsg) {
    //console.log('sfObj save :: '+JSON.stringify(sfObj));
    //console.log('_sfObj :: '+JSON.stringify(__sfObj.current));
    //console.log('sectionCurrentList :: '+JSON.stringify(sectionCurrentList));
    let retValue = false;
    setSpinner(true);
    const reqBody = {checkObj : sfObj , sections : sectionCurrentList};
    if(true){
      await axios.post(`${fetchUrl}/SaveCheckListTemplate`,reqBody)
      .then((res) => {
        //console.log("count:::: " + JSON.stringify(res));
        if(res.status === 200){
          retValue = true;
          if(tmsg){
            setToastMessage(tmsg);
          }
          setTemplateUpdated(true);
          setPendingChanges(false);
          setTemplatePublished(sfObj?.Status__c === 'Published');
          if(projectTemplateId && sfObj?.Status__c === 'Published'){
            const fData = new FormData();
            let recTosave = {Id: projectTemplateId, Status__c: 'Active'};
            fData.append("recObj", JSON.stringify(recTosave));
            axios.post(`${fetchUrl}/recSave/Project_Form_Template__c?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`, fData)
          }
        }
      }).catch((err) => {
        console.log(err);
      });
    }
    setSpinner(false);
    return retValue;
  }
  async function onRemoveSection( sectionId ) {
    let reValue = false;
    await axios.post(`${fetchUrl}/removeSection/${sectionId}?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`)
    .then((res) => {
      //console.log("count:::: " + JSON.stringify(res));
      if(res.status === 200){
        reValue = true;
        setToastMessage("Section is successfully deleted.");
      }
    }).catch((err) => {
      console.log(err);
    });
    return reValue;
  }
  async function onRemoveQuestion( questionId ) {
    let reValue = false;
    await axios.post(`${fetchUrl}/removeSectionItem/${questionId}?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`)
    .then((res) => {
      //console.log("count:::: " + JSON.stringify(res));
      if(res.status === 200){
        reValue = true;
        setToastMessage("Question is successfully deleted.");
      }
    }).catch((err) => {
      console.log(err);
    });
    return reValue;
  }
  const onMoveSection_Drag = (result) => {
    if (!result.destination) {
      return;
    }
    dispatch({type: 'MOVE_SECTION' , from : result.source.index , to : result.destination.index});
  };
  function getClonnedSection (section){
    const reValue = cloneDeep(section);
    if(reValue.Id){
      reValue.Id = undefined;
    }
    reValue.identifier = makeTestRecId();
    if(reValue?.items?.length > 0 ){
      let items = [];
      for(let k = 0; k < reValue?.items?.length ; k++){
        let itm = getClonnedQuestion(reValue?.items[k]);
        items.push(itm);
      }
      reValue.items = items;
    }
    //console.log('clonnedSection :: ',JSON.stringify(reValue));
    return reValue;
  }
  function getClonnedQuestion (question) {
    const reValue = cloneDeep(question);
    if(reValue.Id){
      reValue.Id = undefined;
    }
    reValue.identifier = makeTestRecId();
    return reValue;
  }
  function setNumberingOfList(listArray , startIndex = 0){
    for (let i = startIndex; i < listArray?.length; i++) {
      listArray[i].Numbering_Sequence__c = i + 1;
    }
    return listArray;
  }
  function reducerF(state, action) {
    //console.log('action.type :: '+action.type);
    switch (action.type) {
      case 'ADD_SECTION': {
        const nextS = cloneDeep(sectionObject);
        nextS.Numbering_Sequence__c = state.length + 1;
        nextS.identifier = makeTestRecId();
        if (nextS.items?.length > 0) {
          for (const itm of nextS.items) {
            if (!itm.identifier) {
              itm.identifier = makeTestRecId();
            }
          }
        }
        setSelectedSection(nextS.identifier);
        let tmpList = cloneDeep(state);
        if (!tmpList) {
          tmpList = [];
        }
        const sign_ind = tmpList?.findIndex((sec)=> sec.Name === 'Signature');
        if(sign_ind > -1){
          nextS.Numbering_Sequence__c = state.length ;
          tmpList[sign_ind].Numbering_Sequence__c = state.length + 1 ;
          tmpList.splice(sign_ind, 0, nextS);
        }else{
          tmpList.push(nextS);
        }
        setTimeout(()=>{setEditableElement(nextS.identifier);},100);
        setPendingChanges(true);
        checkPublishRequiredFields(tmpList);
        return tmpList;
      }
      case 'DUPLICATE_SECTION': {
        const tmpSec_r = cloneDeep(state);
        const nextS = getClonnedSection(tmpSec_r[action.sectionIndex]);
        setSelectedSection(nextS.identifier);
        tmpSec_r.splice(action.sectionIndex + 1 , 0, nextS);
        setNumberingOfList(tmpSec_r , action.sectionIndex);
        setTimeout(()=>{setEditableElement(nextS.identifier);},100);
        setPendingChanges(true);
        return tmpSec_r;
      }
      case 'ADD_SIGN_SECTION': {
        const nextS = cloneDeep(sectionObject);
        nextS.Name = 'Signature';
        nextS.Numbering_Sequence__c = state.length + 1;
        nextS.identifier = makeTestRecId();
        if (nextS.items?.length > 0) {
          for (const itm of nextS.items) {
            if (!itm.identifier) {
              itm.identifier = makeTestRecId();
            }
            itm.Response_Type__c = 'Signature';
            itm.Response_Options__c = null;
            itm.responseOptions = undefined;
          }
        }
        setSelectedSection(nextS.identifier);
        const tmpList = cloneDeep(state);
        if (!tmpList) {
          tmpList = [];
        }
        tmpList.push(nextS);
        setPendingChanges(true);
        checkPublishRequiredFields(tmpList);
        return tmpList;
      }
      case 'REM_SECTION_WARNING': {
        setDispatchDetail({ for: 'SEC', ...action, type: 'REM_SECTION' });
        if(!skipSectionDialog){
          setOpenSectionDeleteDialog(true);
        } else {
          setOpenSectionDeleteDialog(false);
          setTimeout(() => {
            dispatch({ for: 'SEC', ...action, type: 'REM_SECTION' });
          }, 100);
        }
        return cloneDeep(state);
      }
      case 'REM_SECTION': {
        const tmpSec_r = cloneDeep(state);
        if (tmpSec_r) {
          let id_;
          if (tmpSec_r[action.sectionIndex]?.Id) {
            id_ = tmpSec_r[action.sectionIndex].Id;
          }
          if (id_) {
            onRemoveSection(id_);
          }else{
            setToastMessage("Section is successfully deleted.");
          }
          tmpSec_r.splice(action.sectionIndex, 1);
          if (tmpSec_r?.length > 0 && action.sectionIndex < tmpSec_r?.length) {
            setNumberingOfList(tmpSec_r , action.sectionIndex);
          }
          if (tmpSec_r?.length > 0) {
            setSelectedSection(tmpSec_r[0].identifier);
          } else {
            setSelectedSection(null);
          }
          setDispatchDetail(null);
          setPendingChanges(true);
        }
        checkPublishRequiredFields(tmpSec_r);
        return tmpSec_r;
      }
      case 'MOVE_SECTION': {
        const tmpSec_ms = cloneDeep(state);
        const sign_ind = tmpSec_ms?.findIndex((sec)=> sec.Name === 'Signature');
        let mov = true;
        if(sign_ind > - 1){
          mov = action.to < sign_ind;
        }
        if(mov){
          let f = tmpSec_ms.splice(action.from, 1)[0];
          tmpSec_ms.splice(action.to, 0, f);
          let startIndex = action.from < action.to ? action.from : action.to ;
          setNumberingOfList(tmpSec_ms , startIndex);
          setPendingChanges(true);
        }
        return tmpSec_ms;
      }
      case 'SECTION_VAL_CHANGE': {
        const tmpSec_scv = cloneDeep(state);
        tmpSec_scv[action.sectionIndex][action.fieldKey] = action.value;
        setPendingChanges(true);
        checkPublishRequiredFields(tmpSec_scv);
        return tmpSec_scv;
      }
      case 'HANDLE_SECTION_REPLACE': {
        //replace current state with new state (action.sectionList)
        checkPublishRequiredFields(action.sectionList);
        return action.sectionList;
      }
      case 'ADD_QUESTION': {
        const tmpState = cloneDeep(state);
        const selectedSectionItem = tmpState[action.sectionIndex];
        const nextQ_aq = cloneDeep(sectionQuetion);
        if(selectedSectionItem.Name === 'Signature'){
          nextQ_aq.Response_Type__c = 'Signature';
        }
        nextQ_aq.Numbering_Sequence__c = selectedSectionItem.items.length + 1;
        nextQ_aq.identifier = makeTestRecId();
        selectedSectionItem.items.push(nextQ_aq);
        setTimeout(()=>{setEditableElement(nextQ_aq.identifier);},100);
        setPendingChanges(true);
        checkPublishRequiredFields(tmpState);
        return tmpState;
      }
      case 'DUPLICATE_QUESTION': {
        const tmpState = cloneDeep(state);
        const selectedSectionItem = tmpState[action.sectionIndex];
        if(selectedSectionItem.items?.length > 0 ){
          const nextQ_aq = getClonnedQuestion(selectedSectionItem.items[action.questionIndex]);
          selectedSectionItem.items.splice(action.questionIndex + 1 , 0, nextQ_aq);
          setNumberingOfList(selectedSectionItem.items , action.questionIndex);
          setTimeout(()=>{setEditableElement(nextQ_aq.identifier);},100);
          setPendingChanges(true);
        }
        return tmpState;
      }
      case 'HANDLE_CHANGE_QUESTION_FIELD': {
        const tmpSec = cloneDeep(state);
        const qArr = tmpSec[action.sectionIndex].items;
        qArr[action.questionIndex][action.fieldKey] = action.value;
        if (action.fieldKey === 'responseOptions') {
          let jsB;
          if (action.value && action.value.length > 0) {
            jsB = JSON.stringify(action.value);
          }
          qArr[action.questionIndex]['Response_Options__c'] = jsB;
        }else if(action.fieldKey === 'Response_Type__c'){
          if(action.value != 'Multiple Choice' && action.value != 'Dropdown' && action.value != 'Preconfigured'){
            qArr[action.questionIndex]['Conditional_Logic__c'] = false;
          }
        }
        setPendingChanges(true);
        checkPublishRequiredFields(tmpSec);
        return tmpSec;
      }
      case 'REM_QUESTION_WARNING': {
        setDispatchDetail({ for: 'QUE', ...action, type: 'REM_QUESTION' });
        if(!skipQuestionDialog){
          setOpenQuestionDeleteDialog(true);
        }else{
          setOpenQuestionDeleteDialog(false);
          setTimeout(() => {
            dispatch({ for: 'QUE', ...action, type: 'REM_QUESTION' });
          }, 100);
        }
        return cloneDeep(state);
      }
      case 'REM_QUESTION': {
        const tmpSec_rq = cloneDeep(state);
        if (tmpSec_rq[action.sectionIndex].items) {
          let id_;
          if (tmpSec_rq[action.sectionIndex].items[action.questionIndex]?.Id) {
            id_ = tmpSec_rq[action.sectionIndex].items[action.questionIndex].Id;
          }
          if (id_) {
            onRemoveQuestion(id_);
          }else{
            setToastMessage("Question is successfully deleted.");
          }
          tmpSec_rq[action.sectionIndex].items.splice(action.questionIndex, 1);
          if (tmpSec_rq[action.sectionIndex].items?.length > 0 && action.questionIndex < tmpSec_rq[action.sectionIndex].items?.length) {
            setNumberingOfList(tmpSec_rq , action.questionIndex);
          }
          setEditableElement(null);
          setDispatchDetail(null);
          setPendingChanges(true);
          checkPublishRequiredFields(tmpSec_rq);
        }
        return tmpSec_rq;
      }
      case 'MOVE_QUESTION': {
        const tmpSec_mq = cloneDeep(state);
        if (tmpSec_mq[action.sectionIndex].items) {
          let f = tmpSec_mq[action.sectionIndex].items.splice(action.from, 1)[0];
          // insert stored item into position `to`
          tmpSec_mq[action.sectionIndex].items.splice(action.to, 0, f);
          let startIndex = action.from < action.to ? action.from : action.to;
          setNumberingOfList(tmpSec_mq[action.sectionIndex].items , startIndex);
          setPendingChanges(true);
        }
        return tmpSec_mq;
      }
      default:
        return state;
    }
  }
  function checkPublishRequiredFields( _sectionCurrentListWithAns = sectionCurrentList ) {
    let reqMissing = false;
    //console.log('totalFieldCount.size :: '+totalFieldCount);
    function stringIsEmpty(_value){
      if ( _value === undefined || _value === "" || _value === null ) {
        return true;
      }
      return false;
    }
    for (let sec of _sectionCurrentListWithAns) {
      if(stringIsEmpty(sec.Name)){
        reqMissing = true;
        break;
      }
      if(sec.items?.length > 0){
        for (let itm of sec.items) {
          //console.log('itm :: ',itm);
          if(stringIsEmpty(itm.Question__c) || stringIsEmpty(itm.Response_Type__c)){
            reqMissing = true;
            break;
          }
          if((itm.Response_Type__c === 'Checkbox' || itm.Response_Type__c === 'Multiple Choice')){
            if( !(itm.responseOptions?.length > 1) ){
              reqMissing = true;
              break;
            }
          }
          if(reqMissing === true){
            break;
          }
        }
      }
      if(reqMissing === true){
        break;
      }
    }
    setPublishButtonDisabled(reqMissing);
    return reqMissing;
  }
  /*function reducerF(state, action) {
    //console.log('action.type :: '+action.type);
    switch (action.type) {
      case 'ADD_SECTION': {
        const nextS = cloneDeep(sectionObject);
        nextS.Numbering_Sequence__c = state.sectionCurrentList.length + 1;
        nextS.identifier = makeTestRecId();
        if (nextS.items?.length > 0) {
          for (const itm of nextS.items) {
            if (!itm.identifier) {
              itm.identifier = makeTestRecId();
            }
          }
        }
        setSelectedSection(nextS.identifier);
  
        // Create a new state object with the updated sectionCurrentList
        return {
          ...state,
          sectionCurrentList: [...state.sectionCurrentList, nextS],
        };
      }
      case 'ADD_SIGN_SECTION': {
        const nextS = cloneDeep(sectionObject);
        nextS.Numbering_Sequence__c = state.length + 1;
        nextS.identifier = makeTestRecId();
        if (nextS.items?.length > 0) {
          for (const itm of nextS.items) {
            if (!itm.identifier) {
              itm.identifier = makeTestRecId();
            }
            itm.Response_Type__c = 'Signature';
            itm.Response_Options__c = null;
            itm.responseOptions = undefined;
          }
        }
        setSelectedSection(nextS.identifier);
        const tmpList = cloneDeep(state);
        if (!tmpList) {
          tmpList = [];
        }
        tmpList.push(nextS);
        return tmpList;
      }
      case 'REM_SECTION_WARNING': {
        setDispatchDetail({ for: 'SEC', ...action, type: 'REM_SECTION' });
        return cloneDeep(state);
      }
      case 'REM_SECTION': {
        const tmpSec_r = cloneDeep(state);
        if (tmpSec_r) {
          let id_;
          if (tmpSec_r[action.sectionIndex]?.Id) {
            id_ = tmpSec_r[action.sectionIndex].Id;
          }
          if (id_) {
            //onRemoveSection(id_);
          }
          tmpSec_r.splice(action.sectionIndex, 1);
          if (tmpSec_r?.length > 0 && action.sectionIndex < tmpSec_r?.length) {
            for (let i = action.sectionIndex; i < tmpSec_r?.length; i++) {
              tmpSec_r[i].Numbering_Sequence__c = i + 1;
            }
          }
          if (tmpSec_r?.length > 0) {
            setSelectedSection(tmpSec_r[0].identifier);
          } else {
            setSelectedSection(null);
          }
          setDispatchDetail(null);
        }
        return tmpSec_r;
      }
      case 'MOVE_SECTION': {
        const tmpSec_ms = cloneDeep(state);
        let f = tmpSec_ms.splice(action.from, 1)[0];
        tmpSec_ms.splice(action.to, 0, f);
        for (let i = action.from < action.to ? action.from : action.to; i < tmpSec_ms.length; i++) {
          tmpSec_ms[i].Numbering_Sequence__c = i + 1;
        }
        return tmpSec_ms;
      }
      case 'SECTION_VAL_CHANGE': {
        const tmpSec_scv = cloneDeep(state);
        tmpSec_scv[action.sectionIndex][action.fieldKey] = action.value;
        return tmpSec_scv;
      }
      case 'HANDLE_SECTION_REPLACE': {
        //replace current state with new state (action.sectionList)
        return action.sectionList;
      }
      case 'ADD_QUESTION': {
        const tmpState = cloneDeep(state);
        const selectedSectionItem = tmpState.sectionCurrentList[action.sectionIndex];
        const nextQ_aq = cloneDeep(sectionQuetion);
        nextQ_aq.Numbering_Sequence__c = selectedSectionItem.items.length + 1;
        nextQ_aq.identifier = makeTestRecId();
        selectedSectionItem.items.push(nextQ_aq);
      
        // Create a new history array with the updated sectionCurrentList
        const newHistory = tmpState.history.slice(0, tmpState.currentIndex + 1);
        console.log("newHistory ::", newHistory);
        newHistory.push({ sectionCurrentList: cloneDeep(tmpState.sectionCurrentList) });
        console.log("newHistory1 ::", newHistory);
        setTimeout(() => {
          setSelectedQuestion(nextQ_aq.identifier);
        }, 100);
      
        // Return a new state object with the updated sectionCurrentList and history
        return {
          ...tmpState,
          sectionCurrentList: tmpState.sectionCurrentList,
          history: newHistory,
          currentIndex: newHistory.length - 1,
        };
      }
      case 'HANDLE_CHANGE_QUESTION_FIELD': {
        const tmpSec = cloneDeep(state);
        const qArr = tmpSec[action.sectionIndex].items;
        qArr[action.questionIndex][action.fieldKey] = action.value;
        if (action.fieldKey == 'responseOptions') {
          let jsB;
          if (action.value && action.value.length > 0) {
            jsB = JSON.stringify(action.value);
          }
          qArr[action.questionIndex]['Response_Options__c'] = jsB;
        }
        return tmpSec;
      }
      case 'REM_QUESTION_WARNING': {
        setDispatchDetail({ for: 'QUE', ...action, type: 'REM_QUESTION' });
        return cloneDeep(state);
      }
      case 'REM_QUESTION': {
        const tmpSec_rq = cloneDeep(state);
        if (tmpSec_rq[action.sectionIndex].items) {
          let id_;
          if (tmpSec_rq[action.sectionIndex].items[action.questionIndex]?.Id) {
            id_ = tmpSec_rq[action.sectionIndex].items[action.questionIndex].Id;
          }
          if (id_) {
            // onRemoveQuestion(id_);
          }
          tmpSec_rq[action.sectionIndex].items.splice(action.questionIndex, 1);
          if (tmpSec_rq[action.sectionIndex].items?.length > 0 && action.questionIndex < tmpSec_rq[action.sectionIndex].items?.length) {
            for (let i = action.questionIndex; i < tmpSec_rq[action.sectionIndex].items?.length; i++) {
              tmpSec_rq[action.sectionIndex].items[i].Numbering_Sequence__c = i + 1;
            }
          }
          setSelectedQuestion(null);
          setDispatchDetail(null);
        }
        return tmpSec_rq;
      }
      case 'MOVE_QUESTION': {
        const tmpSec_mq = cloneDeep(state);
        if (tmpSec_mq[action.sectionIndex].items) {
          //console.log('action.sectionIndex '+action.sectionIndex);
          //console.log('tmpSec_mq[action.sectionIndex].items?. '+tmpSec_mq[action.sectionIndex].items?.length);
          let f = tmpSec_mq[action.sectionIndex].items.splice(action.from, 1)[0];
          // insert stored item into position `to`
          tmpSec_mq[action.sectionIndex].items.splice(action.to, 0, f);
          //console.log('tmpSec_mq[action.sectionIndex].items?.length '+tmpSec_mq[action.sectionIndex].items?.length);
          for (let i = action.from < action.to ? action.from : action.to; i < tmpSec_mq[action.sectionIndex].items?.length; i++) {
            tmpSec_mq[action.sectionIndex].items[i].Numbering_Sequence__c = i + 1;
            //console.log('i '+i);
          }
        }
        return tmpSec_mq;
      }
      case 'UNDO': {
        const newCurrentIndex = Math.max(0, state.currentIndex - 1);
        console.log("newCurrentIndex ::", newCurrentIndex);
        const newState = { ...state, currentIndex: newCurrentIndex };
        console.log("newState ::", newState);
        return { ...newState, sectionCurrentList: newState.history[newCurrentIndex].sectionCurrentList };
      }

      case 'REDO': {
        const newCurrentIndex = Math.min(state.history.length - 1, state.currentIndex + 1);
        const newState = { ...state, currentIndex: newCurrentIndex };
        return { ...newState, sectionCurrentList: newState.history[newCurrentIndex].sectionCurrentList };
      }

      case 'SET_HISTORY': {
        return { ...state, history: action.payload, currentIndex: action.payload.length - 1 };
      }
      default:
        return state;
    }300000
  }*/
  const handleDuplicateTemplate =(value)=>{
    setCurrentStep(2);
    setDuplicateTemplate(true);
    setFormDetail(true);
  }
  return (
    <>
      <Dialog open={(sectionCurrentList?.length == 0 && openFormDetails && !sfObj?.Id) ? false : true} fullScreen>
        {!openFormDetails && <IdleTimer ref={idleTimerRef} timeout={(2 * 60000)} onIdle={onIdelAutoSave} /> }
        <div className="builder">
          <div className="builder_head" onClick={()=>handleClickAway()}>
            <div className="builder_head_left" onClick={()=>{
                if(pendingChanges){
                  console.log('Pending Changes');
                  setPendingWaring(true);
                }else{
                  handleOnClose();
                }
              }}>
              <div className="builder_head_left-container">
                <div>
                  <img src="/left.svg" alt="leftIcon" />
                </div>
                <div className="header_label">
                  Go to Templates
                </div>
              </div>
            </div>
            <div className="builder_head_center">
              {showSpinner && 
                <div className="header_label_dark">Loading...</div>
              }
              {!showSpinner&& 
                <>
                  <div className="header_label_dark">{sfObj?.Name}</div>
                  {!templatePublished  &&
                    <div className="clickable custom_button"
                      onClick={() => {
                        /*if (sfObj?.Id) {
                          setCurrentStep(2);
                        } else {
                          setCurrentStep(1);
                        }*/
                        setCurrentStep(2);
                        setFormDetail(true);
                      }}>
                      <img src="/Pencil.svg" alt="leftIcon" />
                    </div>
                  }
                </>
              }
            </div>
            <div className="builder_head_right">
              {!templatePublished &&
                <>
                  <div className="header_icon">
                    <img style={{ cursor: "pointer" }} src="/show.svg" alt="leftIco" />
                  </div>
                  <div>
                    <Button className="custom_button cancel_button" onClick={() => onSaveAsDraft(true)} disabled={showSpinner}>Save as Draft</Button>
                  </div>
                  <div>
                    <Button className="custom_button apply_button" onClick={onPublishTemplate} disabled={showSpinner || publishButtonDisabled}>
                      Publish Template
                    </Button>
                  </div>
                </>
              }
               {templatePublished && templateActive &&
               <>
               <Box style={{marginRight: "5px", paddingRight: "10px", borderRight: "1px solid #D8D8D8", height: "15px", marginTop: "10px"}} onClick={()=> handleDuplicateTemplate()}>
               <img src="/copy.svg" alt="copyIcon"/>
               </Box>
                <div>
                  <Button className="custom_button apply_button" onClick={()=>onAccept(sfObj)} disabled={showSpinner || publishButtonDisabled}>
                    Create Form
                  </Button>
                </div>
                </>
              }
            </div>
          </div>
          <div className="builder_body">
            {showSpinner && <Spinner />}
            <div className="builder_body_left" onClick={()=>handleClickAway()}>
              <div className="builder_body_left-container">
                <div className="header_label_dark">
                  Template Outline
                </div>
                <div className="builder_body_left-container_list">
                  <DragDropContext onDragEnd={onMoveSection_Drag}>
                    <Droppable droppableId="out_line_list" isDropDisabled={templatePublished}>
                      {(drop_source) => (
                        <div {...drop_source.droppableProps} ref={drop_source.innerRef}>
                          {sectionCurrentList && sectionCurrentList.map((section, index) => (
                            <Draggable key={'drag-section-'+section.identifier} draggableId={'drag-section-'+section.identifier} index={index} isDragDisabled={section.Name === 'Signature' || templatePublished}>
                              {(drag_source) => (
                                <div ref={drag_source.innerRef} {...drag_source.draggableProps} className={`builder_body_left-container_list-item  ${selectedSection === section.identifier ? 'section_list_selected' : ''}`}>
                                  {!templatePublished &&
                                    <div style={{ width: "10%" }}>
                                      <span className="over_hover">
                                        <img src="/drag_hori.svg" alt="dragIcon" {...drag_source.dragHandleProps}/>
                                      </span>
                                    </div>
                                  }
                                  <div className="default_text text_no-wrap" style={{ width: "80%" }} onClick={() => setSelectedSection(section.identifier)}>
                                    <Tooltip title={section.Name && section.Name.trim() != '' ? section.Name : 'Untitled Section'} placement="top" PopperProps={{ className: "version-action-tooltip" }} arrow>
                                      {section.Numbering_Sequence__c}&nbsp;&nbsp;{section.Name && section.Name.trim() != '' ? section.Name : 'Untitled Section'}
                                    </Tooltip>
                                  </div>
                                  <div style={{ width: "10%" , marginLeft : 'auto' }}>
                                    {templatePublished &&
                                      <>0/{ (section?.items?.filter((itm)=>itm.Optional__c === false)).length}</>
                                    }
                                    {!templatePublished &&
                                      <span className="over_hover">
                                        <img src="/more sub nav.svg" alt="dragIcon" />
                                      </span>
                                    }
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {drop_source.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              </div>
            </div>
            <div ref={builderBodyCenterRef} className="builder_body_center">
              {sectionCurrentList && sectionCurrentList.map((section, index) => (
                <ChecklistSectionCard
                  key={section.identifier}
                  index={index}
                  dispatchObj={dispatch}
                  templatePublished={templatePublished}
                  totalSections={sectionCurrentList.length}
                  sectionData={section}
                  selectedSection={selectedSection}
                  setSelectedSection={setSelectedSection}
                  currentEditableElement={currentEditableElement}
                  setEditableElement={setEditableElement}
                  clickAwayPressed={clickAwayPressed} 
                  responseOptions={templateOptions?.questionTypeOptions?.options}
                  defaultResponse={templateOptions?.questionTypeOptions?.default}
                />
              ))}
            </div>
            <div className="builder_body_right" onClick={()=>handleClickAway()}>
              {!templatePublished && 
                <div className="builder_body_right_tool" ref={builderBodyRightToolRef}>
                   <Tooltip title="Add Question" placement="right" >
                  <div className="builder_body_right_tool_inside" onClick={() => { handleAddNewQuestion() }} style={!selectedSection ? { opacity: '0.7' } : {}}>
                    <img src="/add question.svg" alt="" />
                  </div>
                  </Tooltip>
                  <Tooltip title="Add Section" placement="right" >
                  <div className="builder_body_right_tool_inside" onClick={handleAddNewSection}>
                    <img src="/section.svg" alt="" />
                  </div>
                  </Tooltip>
                  <Tooltip title="Add Signature" placement="right" >
                  <div className="builder_body_right_tool_inside" onClick={handleAddNewSignature}>
                    <img src="/signature.svg" alt="" />
                  </div>
                  </Tooltip>
                  <div className="builder_body_right_tool_inside">
                    <img src="/undo.svg" alt="" />
                  </div>
                  <div className="builder_body_right_tool_inside">
                    <img src="/redo.svg" alt="" />
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </Dialog>
      {dispatchjDetail && (openQuestionDeleteDialog || openSectionDeleteDialog) &&
        <ConfirmDialogCheckbox
          credential={userData}
          nameOfDontShowProperty= {dispatchjDetail.for == 'QUE'? 'checklistSkipDeleteQuestionDialog' : 'checklistSkipDeleteSectionDialog'}
          DialogData={dispatchjDetail.for == 'QUE' ? confirmDeleteQuestionDialog : confirmDeleteSectionDialog}
          onAccept={() => {
            dispatch(dispatchjDetail);
            setToastMessage(dispatchjDetail.for == 'QUE' ? "Question is successfully deleted." : "Section is successfully deleted.");
          }}
          onCancel={() => {
            setDispatchDetail(null);
          }}
        />
      }
      {openFormDetails &&
        <ChecklistTemplateDetail
          sfObj={sfObj}
          templateList={templateList}
          credential={credential}
          templateOptions={templateOptions}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          splitButtonAction={splitButtonAction}
          duplicatTemplate={duplicatTemplate}
          onCancel={() => {
            if (sectionCurrentList?.length === 0 && !sfObj?.Id) {
              onCancel();
            }
            setDuplicateTemplate(false);
            setFormDetail(false);
          }}
          onAccept={async (sfreocrd, selectedTemplateId) => {
            //console.log('sfreocrd ::', sfreocrd);
            setSpinner(true);
            let rec = cloneDeep(sfreocrd) ;
            setFormDetail(false);
            const formData = new FormData();
            formData.append("recObj", JSON.stringify(rec));
            await axios.post(`${fetchUrl}/recSave/Form_Template__c`, formData)
              .then(async (res) => {
                //console.log("count:::: " + JSON.stringify(res));
                if (res.data.status == 200) {
                  rec.Id = res.data.retObj.id;
                  checklistServerId.current = rec.Id;
                  setTemplateUpdated(true);
                  setVariables(rec);
                  console.log("selectedTemplateId ::", selectedTemplateId);
                  if(selectedTemplateId == "blank"){
                  handleAddNewSection();
                  let tmsg = `Template Details has been updated.`;
                  setToastMessage(tmsg);
                  }else{
                    handleCloneTemplate(selectedTemplateId, rec);
                  }
                  if(!sfreocrd.Id){
                    const formData2 = new FormData();
                    //let issueObj = sfIssueObj;
                    let rec2 = {Form_Template__c : rec.Id , Project__c : credential?.projectId};
                    formData2.append("recObj", JSON.stringify(rec2));
                    axios.post(`${fetchUrl}/recSave/Project_Form_Template__c`, formData2).then(async (res) => {
                      setProjectTemplateId(res.data.retObj.id);
                    })
                  }
                }
              }).catch((err) => {
                console.log('recSave/Form_Template__c', err);
                setSpinner(false);
              });
              if(selectedTemplateId == "blank") {
              setSpinner(false);
              }
          }}
        />
      }
      {openPendingWarning &&
        <ConfirmDialog
          DialogData={pendinChangesModal}
          onAccept={async () => {
            setPendingWaring(false);
            handleOnClose();
          }}
          onAction={async () => {
            setPendingWaring(false);
            onSaveAsDraft(true);
          }}
          onCancel={() => {
            setPendingWaring(false);
          }}
        />
      }
      {toastMessage &&
        <ToastComponent message={toastMessage} handleClose={()=>setToastMessage(null)}/>
      }
    </>
  );
};
export default BuildChecklistTemplate;
